import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import "./Pages.css";
import secureLocalStorage from "react-secure-storage";
import SideBar from "../SideBar";

function DashUsers() {
  const form = useRef();
  const [allUsers, setAllUsers] = useState([]);
  const [name, setnameRegister] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("user");
  const [filterRole, setFilterRole] = useState("all"); // new state for filtering

  useEffect(() => {
    fetchData();
  }, [filterRole]);

  async function fetchData() {
    const token = secureLocalStorage.getItem("token");

    try {
      const response = await axios.get(
        "https://api.leadqode.com/api/users/getall"
      );

      setAllUsers(response.data.user);
    } catch (error) {
      console.error("what", error);
    }
  }

  const handleAdd = async () => {
    try {
      await axios.post("https://api.leadqode.com/api/users/register", {
        name,
        email,
        password,
        role,
      });

      toast.success("Added Successfully", 2000);
      fetchData();
      setnameRegister("");
      setEmail("");
      setPassword("");
      setRole("user"); // Set the default role back to "user"
    } catch (error) {
      console.error(error);
    }
  };

  const deleteUser = async (id) => {
    await axios.delete(`https://api.leadqode.com/api/users/delete/${id}`);
    toast.success("Deleted Successfully", 2000);
    await fetchData();
  };

  const handleFilterChange = (e) => {
    setFilterRole(e.target.value);
  };

  const filteredUsers = filterRole === "all"
    ? allUsers
    : allUsers.filter(user => user.role === filterRole);

  return (
    <div className="compflex">
      <SideBar />
      <div className="container-info">
        <h1 className="Item-dash-header">Users</h1>
        <form
          ref={form}
          className="contact-formm"
          encType="multipart/form-data"
        >
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <TextField
                className="inputadd"
                label="Name"
                value={name}
                onChange={(e) => setnameRegister(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="inputadd"
                type="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="inputadd"
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                label="Role"
                className="inputadd"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="user">User</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={1}>
              <Button
                className="buttonadd"
                variant="contained"
                color="primary"
                onClick={handleAdd}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
        <br />
        <br />
        <div>
          <TextField
            select
            label="Filter by Role"
            className="inputadd"
            value={filterRole}
            onChange={handleFilterChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="user">User</MenuItem>
          </TextField>
          <TableContainer component={Paper}>
            <Table style={{ width: "80%" }}>
              <TableHead>
                <TableRow>
                  <TableCell className="dashwork_thead_tr_td">
                    FullName
                  </TableCell>
                  <TableCell className="dashwork_thead_tr_td">Email</TableCell>
                  <TableCell className="dashwork_thead_tr_td">Role</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>
                      <Button
                        color="secondary"
                        onClick={() => deleteUser(user._id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </div>
  );
}

export default DashUsers;
