import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./Components/Auth/Login/Login";
import Dashboard from "./Dashboard/Dashboard";
import DashWork from "./Dashboard/Sidebar/Pages/DashWork";
import DashInfo from "./Dashboard/Sidebar/Pages/DashInfo";
import DashUsers from "./Dashboard/Sidebar/Pages/DashUsers";

function PrivateRoute({ element, adminRequired }) {
  const isLoggedIn = localStorage.getItem("loggedIn");
  const role = localStorage.getItem("role");
  const isAdmin = role === "admin";

  if (isLoggedIn === "true" && (!adminRequired || isAdmin)) {
    return element;
  } else {
    return <Navigate to="/" />;
  }
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const changeLoggedIn = () => {
    let loggedIn = localStorage.getItem("loggedIn");
    setIsLoggedIn(!!loggedIn);
  };

  useEffect(() => {
    changeLoggedIn();
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={<PrivateRoute element={<Dashboard />} adminRequired />}
        />
        <Route
          path="/dashwork"
          element={<PrivateRoute element={<DashWork />} adminRequired />}
        />
        <Route
          path="/dashinformations"
          element={<PrivateRoute element={<DashInfo />} adminRequired />}
        />
        <Route
          path="/dashusers"
          element={<PrivateRoute element={<DashUsers />} adminRequired />}
        />
      </Routes>
    </div>
  );
}

export default App;
