import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import "./Pages.css";
import SideBar from "../SideBar";

function DashInfo() {
  const form = useRef();
  const [allinfo, setAllInfo] = useState([]);
  const [selectedInfo, setSelectedInfo] = useState({});
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [infoImage, setInfoImage] = useState(null);

  const [newInfo, setNewInfo] = useState({
    type: "",
    subtitle: "",
    title: "",
    description: "",
    image: null,
  });

  const types = ["services", "partners"];

  const APIKEY = "https://api.leadqode.com/api/info/getallinfo";

  const Info = async () => {
    try {
      const res = await axios.get(APIKEY);
      setAllInfo(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    Info();
  }, []);

  const handleAdd = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("type", newInfo.type);
    formData.append("subtitle", newInfo.subtitle);
    formData.append("title", newInfo.title);
    formData.append("description", newInfo.description);
    formData.append("image", infoImage);

    try {
      const response = await axios.post(
        "https://api.leadqode.com/api/info/createinfo",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Added Successfully", { autoClose: 2000 });
      Info();
      setNewInfo({
        type: "",
        subtitle: "",
        title: "",
        description: "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteUser = async (id) => {
    await axios.delete(`https://api.leadqode.com/api/info/deleteinfo/${id}`);
    toast.success("Deleted Successfully", { autoClose: 2000 });
    await Info();
  };

  const handleUpdate = (info) => {
    setSelectedInfo(info);
    setIsUpdateMode(true);
  };

  const handleImage = (e) => {
    setInfoImage(e.target.files[0]);
  };

  const updateUser = async () => {
    const formData = new FormData();

    formData.append("type", selectedInfo.type);
    formData.append("subtitle", selectedInfo.subtitle);
    formData.append("title", selectedInfo.title);
    formData.append("description", selectedInfo.description);

    // Check if a new image is selected
    if (infoImage) {
      formData.append("image", infoImage);
    }

    try {
      await axios.put(
        `https://api.leadqode.com/api/info/updateinfo/${selectedInfo._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Updated Successfully", { autoClose: 2000 });
      setIsUpdateMode(false);
      Info();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="compflex">
      <SideBar />
      <div className="container-info">
        <h1 className="Item-dash-header">Information</h1>

        <div>
          <form
            ref={form}
            className="contact-formm"
            encType="multipart/form-data"
          >
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Select Type</InputLabel>
                  <Select
                    value={isUpdateMode ? selectedInfo.type : newInfo.type}
                    onChange={(e) => {
                      if (isUpdateMode) {
                        setSelectedInfo({
                          ...selectedInfo,
                          type: e.target.value,
                        });
                      } else {
                        setNewInfo({ ...newInfo, type: e.target.value });
                      }
                    }}
                  >
                    {types.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="inputadd"
                  type="text"
                  value={isUpdateMode ? selectedInfo.subtitle : newInfo.subtitle}
                  onChange={(e) => {
                    if (isUpdateMode) {
                      setSelectedInfo({
                        ...selectedInfo,
                        subtitle: e.target.value,
                      });
                    } else {
                      setNewInfo({ ...newInfo, subtitle: e.target.value });
                    }
                  }}
                  placeholder="Enter Sub-Title"
                />
              </Grid>
              <Grid item xs={3}>
                <input
                  className="inputadd"
                  type="text"
                  value={isUpdateMode ? selectedInfo.title : newInfo.title}
                  onChange={(e) => {
                    if (isUpdateMode) {
                      setSelectedInfo({
                        ...selectedInfo,
                        title: e.target.value,
                      });
                    } else {
                      setNewInfo({ ...newInfo, title: e.target.value });
                    }
                  }}
                  placeholder="Enter Title"
                />
              </Grid>
              <Grid item xs={3}>
                <input
                  className="inputadd"
                  type="text"
                  value={isUpdateMode ? selectedInfo.description : newInfo.description}
                  onChange={(e) => {
                    // Update selectedInfo.description if in edit mode
                    if (isUpdateMode) {
                      setSelectedInfo({
                        ...selectedInfo,
                        description: e.target.value,
                      });
                    } else {
                      setNewInfo({ ...newInfo, description: e.target.value });
                    }
                  }}
                  placeholder="Enter Description"
                />
              </Grid>
              <Grid item xs={3}>
                <input
                  className="inputadd"
                  type="file"
                  onChange={(e) => handleImage(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleAdd}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>

        <div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "15%" }}>Subtitle</TableCell>
                  <TableCell style={{ width: "15%" }}>Title</TableCell>
                  <TableCell style={{ width: "20%" }}>Type</TableCell>
                  <TableCell style={{ width: "30%" }}>Description</TableCell>
                  <TableCell style={{ width: "20%" }}>Image</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allinfo.map((info, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell>{info.details[0].subtitle}</TableCell>
                      <TableCell>{info.details[0].title}</TableCell>
                      <TableCell>{info.type}</TableCell>
                      <TableCell>{info.details[0].description}</TableCell>
                      <TableCell>
                        <img
                          className="info_dash_img"
                          src={info.details[0].image}
                          alt={info.details[0].title}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleUpdate(info)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => deleteUser(info._id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                    {isUpdateMode && selectedInfo._id === info._id && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Grid container spacing={3}>

                          <Grid item xs={3}>
                              <input
                                className="inputadd"
                                type="text"
                                value={selectedInfo.subtitle}
                                onChange={(e) =>
                                  setSelectedInfo({
                                    ...selectedInfo,
                                    subtitle: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <input
                                className="inputadd"
                                type="text"
                                value={selectedInfo.title}
                                onChange={(e) =>
                                  setSelectedInfo({
                                    ...selectedInfo,
                                    title: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <FormControl fullWidth>
                                <InputLabel>Select Type</InputLabel>
                                <Select
                                  value={selectedInfo.type}
                                  onChange={(e) =>
                                    setSelectedInfo({
                                      ...selectedInfo,
                                      type: e.target.value,
                                    })
                                  }
                                >
                                  {types.map((type) => (
                                    <MenuItem key={type} value={type}>
                                      {type}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                              <input
                                className="inputadd"
                                type="text"
                                value={selectedInfo.description}
                                onChange={(e) =>
                                  setSelectedInfo({
                                    ...selectedInfo,
                                    description: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <input
                                className="inputadd"
                                type="file"
                                onChange={(e) =>
                                  setInfoImage(e.target.files[0])
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => updateUser()}
                              >
                                <EditIcon />
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setIsUpdateMode(false)}
                              >
                                <DeleteIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </div>
  );
}

export default DashInfo;
