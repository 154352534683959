import React from "react";
import { Link } from "react-router-dom";

import "./Dashboard.css";
import SideBar from "./Sidebar/SideBar";

const Dashboard = () => {
  const isLoggedIn = window.localStorage.getItem("loggedIn");

  return (
    <div className="dashboard">
      <SideBar />

      <div className="dash">
        <h1>LEADQODE Dashboard</h1>

        <div className="container_dash_item">
          <Link to="/dashusers" className="active dash_items">
            Users
          </Link>

          <Link to="/dashinformations" className="active dash_items">
            Informations
          </Link>

          <Link to="/dashwork" className="active dash_items">
            Work
          </Link>
        
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
