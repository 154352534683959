import React, { useEffect, useRef, useState } from "react";
import "./Login.css";
import { BsGoogle } from "react-icons/bs";
import { RiCloseCircleLine } from "react-icons/ri";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  const handleSigninSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("https://api.leadqode.com/api/users/login", { email, password });

      localStorage.setItem("token", res.data.token);
      localStorage.setItem("role", res.data.role);
      localStorage.setItem("loggedIn", true);

      toast.success("You are logged in");
      setErrMsg("You are logged in");

      setTimeout(() => {
        navigate("/dashboard"); // Use navigate hook to redirect
      }, 2000);
    } catch (err) {
      if (!err?.response) {
        toast.error("No server response");
      } else if (err.response?.status === 404) {
        toast.error("Email not found");
      } else if (err.response?.status === 400) {
        toast.error("Incorrect password");
      }
    }
  };

  useEffect(() => {
    const signInButton = document.getElementById("signIn");
    const container = document.getElementById("container");

    const handleSignInClick = () => {
      container.classList.remove("right-panel-active");
    };

    signInButton.addEventListener("click", handleSignInClick);

    return () => {
      signInButton.removeEventListener("click", handleSignInClick);
    };
  }, []);

  return (
    <div className="login_form">
      <div className="container" id="container">
        {/* =======================SIGN IN================================= */}
        <div className="form-container sign-in-container">
          <form className="login_form_form" onSubmit={handleSigninSubmit}>
            <h1>Sign in</h1>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <button className="button_web">Sign In</button>
            {errMsg && <p className="error-message">{errMsg}</p>}
          </form>
        </div>
        {/* =======================END SIGN IN================================= */}
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <h1>Welcome Back!</h1>
              <p className="login_form_p">
                To keep connected with us please login with your personal info
              </p>
              <button className="login_form_button_ghost" id="signIn">
                Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default Login;
